<template>
  <div>
    <div class="container">
      <div class="text-center" style="margin: 64px 0 24px">
        <img src="../assets/logo.png" alt="Congregação Cristã no Brasil" class="img-fluid" style="max-width: 128px;">
        <h2 class="font-weight-bold text-primary mt-3 mb-0">CRS</h2>
        <div>Calendário de Reuniões e Serviços</div>
      </div>
      <div class="row justify-content-center" style="margin-bottom: 102px">
        <div class="col-md-6">
          <div class="d-flex mb-4">
            <input type="search" class="form-control mr-2" placeholder="Pesquisar regional" v-model="termo" @search="pesquisar()">
            <button class="btn btn-secondary text-nowrap px-3" @click="pesquisar()"><i class="fa fa-search"></i><span class="ml-2 d-none d-md-inline">Pesquisar</span></button>
          </div>
          <div class="text-center small mb-2">Por favor selecione sua regional administrativa</div>
          <button class="btn btn-links" @click="selecionar(r)" v-for="r in search">{{ r.nome_regional }}</button>
        </div>
      </div>
      <div class="fixed-bottom bg-light text-center small py-3 border-top">
        <div><router-link to="/regional" class="font-weight-bold text-primary">CRS - 2024</router-link></div>
        <router-link to="/termos-uso" class="text-primary text-decoration-none">Termos de Uso</router-link>
        -
        <router-link to="/privacidade" class="text-primary text-decoration-none">Política de Privavidade</router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import BottonBar from "../components/BottonBar";
  import Calendario from "@/views/Calendario";
  export default {
    name: 'Home',
    components: {Calendario, BottonBar},
    data() {
      return {
        termo: '',
        search: [],
        regionais: [
          { id_regional: 1, nome_regional: 'Guaxupé - MG' },
          { id_regional: 2, nome_regional: 'Formiga - MG' },
          { id_regional: 3, nome_regional: 'São Félix do Xingu - PA' },
        ],
      }
    },

    methods: {
      pesquisar() {
        const termo = this.termo ? this.termo.trim().toLowerCase() : null;
        this.search = termo ? this.regionais.filter(r => r.nome_regional.toLowerCase().includes(termo)) : this.regionais;
      },

      selecionar(r) {
        this.$store.commit('setDataUser', { ...this.user, id_regional: r.id_regional });
        window.location.href = sessionStorage.getItem('redirect') || '/calendario';
      }
    },

    mounted() {
      if (this.user.id_regional) {
        this.$router.push('/calendario');

      } else {
        this.pesquisar();
      }
    },

    computed: {
      user() {
        return this.$store.state.user
      }
    },
  }
</script>
<style scoped>
</style>
