<template>
  <div>
    <top-bar title="CRS" url="/"/>
    <div class="content">
      <div class="container">
        <div>
          <div class="text-center">
            <h4 class="mb-0">Sobre nós</h4>
            <div class="small">Calendário de Reuniões e Serviços</div>
          </div>
          <hr>
          <p>
            Bem-vindo ao CRS - Calendário CCB, o seu companheiro essencial para organizar e participar das reuniões administrativas da Congregação Cristã no Brasil em sua regional. Nosso aplicativo foi cuidadosamente projetado para simplificar sua experiência, garantindo que você nunca perca uma reunião e possa confirmar sua presença de forma fácil e conveniente.
          </p>
          <p>
            Caso haja interesse de fazer parte deste projeto (gratuitamente), basta pedir para os responsáveis pela administração da sua regional entrar em contato com o WhatsApp: (35) 99843-5516.
          </p>
        </div>
        <div class="card my-4">
          <div class="card-body text-center">
            <h6 class="text-primary font-weight-bold mb-0">CRS - 2024</h6>
            <div class="small mb-3">
              <div>Departamento Informática Guaxupé - MG</div>
              <div>Versão atual: {{ version }}</div>
            </div>
            <a class="btn btn-outline-primary" href="https://wa.me/5535998435516" target="_blank">Entrar em contato</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TopBar from "@/components/TopBar";
  export default {
    name: 'Perfil',
    components: {TopBar},
    computed: {
      regional() {
        return this.$store.state.dados.regional
      }
    }
  }
</script>
<style scoped>
</style>
