import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import Termos from '../views/Terms.vue'
import Privacidade from '../views/Policy.vue'
import Explore from '../views/Explore.vue'
import Calendario from '../views/Calendario.vue'
import Listas from '../views/Listas.vue'
import Notificacoes from '../views/Notificacoes.vue'
import Perfil from '../views/Perfil.vue'
import PerfilDados from '../views/PerfilDados.vue'
import MinhasPresencas from '../views/MinhasPresencas.vue'
import Regional from '../views/Regional.vue'
import App from '../views/AppDownload'
import P404 from '../views/P404'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/termos-uso',
    name: 'Termos',
    component: Termos
  },
  {
    path: '/privacidade',
    name: 'Privacidade',
    component: Privacidade
  },
  {
    path: '/explorar',
    name: 'Explore',
    component: Explore
  },
  {
    path: '/calendario',
    name: 'Calendario',
    component: Calendario
  },
  {
    path: '/calendario/:id',
    name: 'ReuniaoHome',
    component: Calendario
  },
  {
    path: '/presenca/:id',
    name: 'CalendarioPresenca',
    component: Calendario
  },
  {
    path: '/listas',
    name: 'Listas',
    component: Listas
  },
  {
    path: '/listas/:id',
    name: 'Lista',
    component: Listas
  },
  {
    path: '/perfil',
    name: 'Perfil',
    component: Perfil
  },
  {
    path: '/perfil/dados',
    name: 'PerfilDados',
    component: PerfilDados
  },
  {
    path: '/presencas',
    name: 'MinhasPresencas',
    component: MinhasPresencas
  },
  {
    path: '/regional',
    name: 'Regional',
    component: Regional
  },
  {
    path: '/notificacoes',
    name: 'Notificacoes',
    component: Notificacoes
  },
  {
    path: '/app',
    name: 'App',
    component: App
  },
  {
    path: '*',
    name: '404',
    component: P404
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router
