<template>
  <div id="app">
    <loading v-if="load" :fullscrean="true" text="Por favor aguarde"/>
    <status-regional v-else-if="error"/>
    <div v-else>
      <router-view/>
    </div>
  </div>
</template>

<script>
  import Loading from "./components/Loading";
  import StatusRegional from "@/components/StatusRegional";

  export default {
    components: {StatusRegional, Loading},
    data() {
      return {
        load: true,
        dados: {},
        user: {},
        error: false
      }
    },

    methods: {
      getDados(id_regional) {
        if (!id_regional) {
          this.load = false;
          sessionStorage.setItem('redirect', window.location.pathname);

          if (this.$route.name !== 'Home') {
            this.$router.push('/');
          }

          return;
        }

        this.$http.get('app/dados/' + id_regional)
          .then(response => {
            if (response.data) {
              this.dados = response.data;
              this.$store.commit('setDadosApp', this.dados);

            } else {
              this.$store.state.id_regional = null;
              this.$store.state.user.id_regional = null;

              if (this.$route.name !== 'Home') {
                this.$router.push('/');
              }
            }

            this.load = false;

          }, res => {
            console.log(res);
            this.error = true;
            this.load = false;
          });
      },

      getUser() {
        const id_regional = this.$route.query.regional;
        let user = this.$helpers.parseJSONObject(localStorage.getItem("userData")) || {};
        this.user = {
          nome_membro: user.nome_membro || '',
          token: user.token || this.$helpers.ID(),
          id_regional: user.id_regional || id_regional || null,
          id_setor: user.id_setor || ''
        };

        this.$store.commit('setDataUser', this.user);
        let confirmacoes = this.$helpers.parseJSONObject(localStorage.getItem('confirmacoes')) || null;

        if (confirmacoes) {
          const now = new Date().getTime();
          confirmacoes = confirmacoes.filter(c => c.timestamp > now);
          localStorage.setItem('confirmacoes', JSON.stringify(confirmacoes));
          this.$store.state.notificacoes = confirmacoes;
        }

        if (this.$route.name !== 'App') {
          this.getDados(this.user.id_regional);

        } else {
          this.load = false;
        }
      }
    },

    mounted() {
      this.getUser();
    }
  }
</script>
